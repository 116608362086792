import { useEffect, useState } from 'react';
import usePriceFeedWebsocketCredentials from './websocketCredentials';
import ReconnectingWebSocket, { Options } from 'reconnecting-websocket';
import * as Sentry from '@sentry/nextjs';
import { unstable_batchedUpdates } from 'react-dom';
import { usePriceFeedState } from '../../components/atoms/PriceFeedStore';
import { Infer, number, object, string } from 'superstruct';

export const IIFLPriceWSResponseSuperStruct = object({
	Exch: string(),
	ExchType: string(),
	Token: number(),
	LastRate: number(),
	LastQty: number(),
	TotalQty: number(),
	High: number(),
	Low: number(),
	OpenRate: number(),
	PClose: number(),
	AvgRate: number(),
	Time: number(),
	BidQty: number(),
	BidRate: number(),
	OffQty: number(),
	OffRate: number(),
	TBidQ: number(),
	TOffQ: number(),
	TickDt: string()
})

export type IIFLPriceWSResponseType = Infer<typeof IIFLPriceWSResponseSuperStruct>


export const PRICE_FEED_LOCAL_STORAGE_KEY = 'price_feed';

export const buildIIFLInstrumentKey = (brokerExchange: string, brokerExchangeType: string, brokerCode: number) => {
	return `${brokerExchange}${brokerExchangeType}${brokerCode}`;
};

const useWriteOHLCFeed = () => {
	// const ids = useGetSoloWithFilters(soloStates.API_FETCH_MODES.IDS, []);
	// const { data } = useWebsocketCredentials();
	// console.log(data)

	const instruments = usePriceFeedState((state) => state.instruments);
	const status = usePriceFeedState((state) => state.status);
	const setStatus = usePriceFeedState((state) => state.setStatus);
	const setLastUpdatedTime = usePriceFeedState((state) => state.setLastUpdatedTime);

	const [subscribed, setSubscribed] = useState([]);
	const [diff, setDiff] = useState([]);
	const [socket, setSocket] = useState<ReconnectingWebSocket | undefined>(undefined);
	const { data: url, mutate } = usePriceFeedWebsocketCredentials();

	useEffect(() => {
		const diff = instruments.filter(
			(instrument) =>
				!subscribed.find(
					(item) =>
						buildIIFLInstrumentKey(item.brokerExchange, item.brokerExchangeType, item.brokerCode) ===
						buildIIFLInstrumentKey(
							instrument.brokerExchange,
							instrument.brokerExchangeType,
							instrument.brokerCode,
						),
				),
		);

		unstable_batchedUpdates(() => {
			if (socket && socket.readyState === 1) {
				setDiff(diff);
				setSubscribed([...subscribed, ...diff]);
			}
		});
	}, [instruments.length]);

	useEffect(() => {
		if (url) {
			setStatus('connecting');
			localStorage.setItem(PRICE_FEED_LOCAL_STORAGE_KEY, JSON.stringify({}));

			const socket = new ReconnectingWebSocket(url, [], {
				connectionTimeout: 4 * 1000, // 4 seconds
			});
			// const socket = new WebSocket(url + 'E');

			socket.onopen = () => {
				console.log('SUBSCRIBED_PRICEFEED_WEBSOCKET');
				const forSocket = instruments.map((item) => ({
					Exch: item.brokerExchange,
					ExchType: item.brokerExchangeType,
					ScripCode: item.brokerCode,
				}));
				if (forSocket.length > 0) {
					socket.send(
						JSON.stringify({
							method: 'MarketFeedV3',
							operation: 'Subscribe',
							ClientCode: 'ABC',
							MarketFeedData: forSocket,
						}),
					);
				}

				setSubscribed(instruments);
			};

			socket.onclose = (e) => {
				if (status !== 'connecting') {
					setStatus('disconnected');
				}
				if (e.code === 1000 && e.reason === '') {
					return;
				}

				const error = new Error();
				error.name = 'WSError';

				if (e.reason === 'timeout') {
					error.message = 'IIFL Price WS Timeout';
				} else if (e.code === 1006 && e.reason === '') {
					error.message = 'IIFL Price WS Drop';
				} else {
					error.message = e.reason;
				}

				Sentry.captureException(error, { extra: { url, websocketCode: e.code, reason: e.reason } });
			};

			socket.onerror = (e) => {
				// console.log('IIFL WS onError Event -', e);
				if (status !== 'connecting') {
					setStatus('disconnected');
				}
				mutate();
			};

			socket.onmessage = (e) => {
				try {
					const data: IIFLPriceWSResponseType = JSON.parse(e.data)[0];
					const existing = JSON.parse(localStorage.getItem(PRICE_FEED_LOCAL_STORAGE_KEY));
					existing[`${data.Exch}${data.ExchType}${data.Token}`] = data;
					localStorage.setItem(PRICE_FEED_LOCAL_STORAGE_KEY, JSON.stringify(existing));
					setStatus('live');
					setLastUpdatedTime(new Date());
				} catch (dataError) {
					console.log(dataError);
				}
			};

			setSocket(socket);

			return () => {
				setStatus('disconnected');
				socket.close();
				console.log('CLOSED_PRICEFEED_WEBSOCKET');
			};
		}
	}, [url]);

	useEffect(() => {
		// const forSocket = diff.map(item => buildIIFLInstrumentKey(item.brokerExchange, item.brokerExchangeType, item.brokerCode));
		const forSocket = diff.map((item) => ({
			Exch: item.brokerExchange,
			ExchType: item.brokerExchangeType,
			ScripCode: item.brokerCode,
		}));
		if (socket && forSocket.length > 0) {
			// socket.send(JSON.stringify({
			// 	'method': 'unsubscribe',
			// 	'operation': 'marketfeed',
			// 	'instruments': forSocket,
			// }));
			socket.send(
				JSON.stringify({
					method: 'MarketFeedV3',
					operation: 'Subscribe',
					ClientCode: 'ABC',
					MarketFeedData: forSocket,
				}),
			);
		}
	}, [JSON.stringify(diff), socket]);
};

export default useWriteOHLCFeed;
