import { useEffect, useState } from 'react';
import { getUserDetails } from '../utils/cognito';
import { useRouter } from 'next/router';
import { UserType } from '../config/app';

const useGetUserDetails = () => {

	const router = useRouter();
	const [details, setDetails] = useState<UserType>({
		email: '',
		name: '',
		admin: false,
		groups: [],
		externalTradeboxOnly: false,
		loginTime: 0,
	});

	useEffect(() => {
		const details = getUserDetails();
		setDetails(details);
	}, [router.pathname]);

	return details;

};

export default useGetUserDetails;