import create from 'zustand';
import { buildIIFLInstrumentKey } from '../../apiHandlers/priceFeed/write';

export type PriceFeedInstrumentType = {
	brokerCode: number;
	brokerExchange: string;
	brokerExchangeType: string;
};

export type PriceFeedStatuses = 'connecting' | 'live' | 'stale' | 'disconnected';
//                               blinking/orange | solid/green | solid/yellow | solid/red

export type PriceFeedStateType = {
	instruments: PriceFeedInstrumentType[];
	status: PriceFeedStatuses;
	lastUpdatedTime: Date | null;
	setStatus: (status: PriceFeedStatuses) => void;
	setLastUpdatedTime: (date: Date) => void;
	pushOne: (instrument: PriceFeedInstrumentType) => void;
	pushMany: (instruments: PriceFeedInstrumentType[]) => void;
};

// TODO PUT PRICES HERE
export const usePriceFeedState = create<PriceFeedStateType>((set) => ({
	instruments: [],
	status: 'connecting',
	lastUpdatedTime: null,
	setStatus: (status) => {
		set((state) => ({ ...state, status }));
	},
	setLastUpdatedTime: (date) => set((state) => ({ ...state, lastUpdatedTime: date })),
	pushOne: (instrument) => {
		if (instrument.brokerCode === 0 || instrument.brokerExchange === '' || instrument.brokerExchangeType === '') {
			return;
		}

		set((state) => {
			let found = state.instruments.find(
				(item) =>
					buildIIFLInstrumentKey(item.brokerExchange, item.brokerExchangeType, item.brokerCode) ===
					buildIIFLInstrumentKey(
						instrument.brokerExchange,
						instrument.brokerExchangeType,
						instrument.brokerCode,
					),
			);

			if (!found) {
				return { ...state, instruments: [...state.instruments, instrument] };
			}

			return state;
		});
	},
	pushMany: (instruments) => {
		const isEmptyInstruments = instruments.some(
			(item) => item.brokerCode === 0 || item.brokerExchange === '' || item.brokerExchangeType === '',
		);

		if (isEmptyInstruments) {
			return;
		}

		set((state) => {
			const current = state.instruments;

			const filteredInstruments = instruments.filter(
				(instrument) =>
					!current.find(
						(item) =>
							buildIIFLInstrumentKey(item.brokerExchange, item.brokerExchangeType, item.brokerCode) ===
							buildIIFLInstrumentKey(
								instrument.brokerExchange,
								instrument.brokerExchangeType,
								instrument.brokerCode,
							),
					),
			);

			return { ...state, instruments: [...current, ...filteredInstruments] };
		});
	},
}));
