import useSWR from 'swr';
import { apiEndPoints } from '../endpoints';
import { fetcher, fetcherOHLCFeed } from '../fetcher';

const usePriceFeedWebsocketCredentials = () => {
	const {
		data,
		error,
		mutate
	} = useSWR([apiEndPoints.PRICE_FEED.WS_CREDENTIALS, null, null], fetcher, {
		refreshInterval: 0,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
	});

	return {
		data: data ? `${data['data']['url']}` : undefined,
		isLoading: !error && !data,
		isError: error,
		mutate
	};

};

export default usePriceFeedWebsocketCredentials;