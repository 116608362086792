import React, { createContext, FC, ReactNode, useContext, useState } from 'react';
import useGetSoloWithFilters from '../../../../apiHandlers/solo/filterSolos';
import { soloStates } from '../../soloVariables';
import { FilterContexType, SELECTED_VERB } from './types';

const FilterContext = createContext<FilterContexType | undefined>(undefined);

export const FilterStateConsumer = ({ children }: { children: (value: FilterContexType) => ReactNode }) => {
	return (
		<FilterContext.Consumer>
			{(value) => {
				if (value === undefined) {
					throw new Error(`FilterStateConsumer must be a child of the FilterState`);
				}

				return children(value);
			}}
		</FilterContext.Consumer>
	);
};

export function useFilterState() {
	const value = useContext(FilterContext);
	if (value === undefined) {
		throw new Error(`useFilterState must be called inside a child of the FilterState`);
	}
	return value;
}

const FilterState: FC = ({ children }) => {
	const [filters, setFilters] = useState<FilterContexType['filters']>([]);
	const [selectedVerb, setSelectedVerb] = useState<FilterContexType['selectedVerb']>(SELECTED_VERB.OR);

	const {
		data: soloIDs,
		isLoading: isSolosLoading,
		isError,
		mutate,
	} = useGetSoloWithFilters(
		soloStates.API_FETCH_MODES.IDS,
		JSON.stringify(filters),
		selectedVerb,
		filters.length > 0,
	);

	const onApply: FilterContexType['onApply'] = (payload) => {
		setFilters(payload);
	};

	const onReset: FilterContexType['onReset'] = () => {
		setFilters([]);
	};

	const value: FilterContexType = {
		filters,
		selectedVerb,
		isSolosLoading,
		soloIDs: isError ? [] : soloIDs,
		setSelectedVerb,
		mutate,
		onReset,
		onApply,
	};
	return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export default FilterState;
