import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { getCognitoUserToken } from '../../../utils/cognito';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { useSWRConfig } from 'swr';
import { soloFieldNames, soloStates } from '../../soloPage/soloVariables';
import { apiEndPoints } from '../../../apiHandlers/endpoints';
import { transformSoloAPIResponseForFrontend } from '../../../apiHandlers/solo/helper';
import { Howl } from 'howler';
import useGetSoloWithFilters from '../../../apiHandlers/solo/filterSolos';
import useGetUserDetails from '../../../hooks/userDetails';
import { useFilterState } from '../../soloPage/toolbar/FiltersQueryBuilder/FilterState';

const SoloNotification = () => {
	const toast = useToast();
	const { mutate: globalMutate } = useSWRConfig();
	const { mutate: filterSoloMutate } = useFilterState();

	const howl = new Howl({
		src: ['/solo-notification-sound.wav'],
	});

	const userDetails = useGetUserDetails();

	useEffect(() => {
		const token = getCognitoUserToken()['accessToken'];
		const socket = new ReconnectingWebSocket(
			`${process.env.NEXT_PUBLIC_BASE_WS}?Authorization=${token}&expires_in=86400&token_type=Bearer`,
		);
		socket.onopen = () => {
			console.log('SUBSCRIBED_SOLO_WEBSOCKET');
		};
		socket.onmessage = (event) => {
			const data = JSON.parse(event.data);

			globalMutate(apiEndPoints.SOLO.SOLO_SUMMARY);

			const parsedSoloData = transformSoloAPIResponseForFrontend(data.solo);
			const instrument = `${parsedSoloData[soloFieldNames.SYMBOL.FRONTEND]} ${
				parsedSoloData[soloFieldNames.EXPIRY.FRONTEND] ? parsedSoloData[soloFieldNames.EXPIRY.FRONTEND] : ''
			} ${
				parsedSoloData[soloFieldNames.STRIKE.FRONTEND] ? parsedSoloData[soloFieldNames.STRIKE.FRONTEND] : ''
			} ${parsedSoloData[soloFieldNames.INS_TYPE.FRONTEND].toUpperCase()}`;
			const toastID = `${parsedSoloData[soloFieldNames.SOLO_ID.FRONTEND]}${data.event}`;

			if (data.event === soloStates.WEBSOCKET_EVENTS.NEW.code) {
				filterSoloMutate();
				if (parsedSoloData[soloFieldNames.EMPLOYEE.FRONTEND] !== userDetails.email) {
					if (!toast.isActive(toastID)) {
						howl.play();
						toast({
							id: toastID,
							title: `${instrument} ${
								Object.entries(soloStates.WEBSOCKET_EVENTS).filter(
									(item) => item[1].code === data.event,
								)[0][1].message
							}`,
							status: 'info',
							isClosable: true,
							variant: 'subtle',
						});
					}
				}
			} else {
				globalMutate([
					apiEndPoints.SOLO.GET_SOLO_BY_ID,
					'ids',
					parsedSoloData[soloFieldNames.SOLO_ID.FRONTEND],
				]);
				if (!toast.isActive(toastID)) {
					howl.play();
					toast({
						id: toastID,
						title: `${instrument} ${
							Object.entries(soloStates.WEBSOCKET_EVENTS).filter(
								(item) => item[1].code === data.event,
							)[0][1].message
						}`,
						status: 'info',
						isClosable: true,
						variant: 'subtle',
					});
				}
			}
		};

		return () => {
			console.log('CLOSING_SOLO_WEBSOCKET');
			socket.close();
		};
	}, [userDetails.email]);

	return null;
};

export default SoloNotification;
