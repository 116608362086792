import { AuthenticationDetails, CognitoUser, CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
import { parseCookies } from 'nookies';
import { parseJwt, parseNameFromEmail } from './text';
import { cognitoExternalGroupForTradebox, UserType } from '../config/app';

const cognitoDomain = () => {
	try {
		return window.location.hostname;
	} catch {
		return 'localhost';
	}
};

export const cognitoUserPool = () =>
	new CognitoUserPool({
		UserPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID,
		ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
		Storage: new CookieStorage({ domain: cognitoDomain(), expires: 1 }),
	});

export const cognitoUser = (username) =>
	new CognitoUser({
		Username: username,
		Pool: cognitoUserPool(),
		Storage: new CookieStorage({ domain: cognitoDomain(), secure: process.env.NODE_ENV === 'production' }),
	});

export const cognitoAuthDetails = (username, password) =>
	new AuthenticationDetails({
		Username: username,
		Password: password,
	});

export const getCognitoUserToken = () => {
	let accessToken = null;
	const user = cognitoUserPool().getCurrentUser();

	if (user) {
		user.getSession((err, session) => {
			if (err) {
				alert(err.message || JSON.stringify(err));
				return { accessToken: accessToken };
			} else {
				accessToken = session.getIdToken().getJwtToken();
			}
		});
	}
	return { accessToken };
};

export const getCurrentUserAttributes = (selectedAttribute: string, callback: (value: any) => void, fallback: any) => {
	// Get the current user from the local storage
	const cognitoUser = cognitoUserPool().getCurrentUser();
	if (cognitoUser !== null) {
		cognitoUser.getSession((err) => {
			if (err !== null) {
				console.log(err);
				return;
			}
			// Session is valid, now we can get the user attributes
			cognitoUser.getUserAttributes((err, attributes) => {
				if (err) {
					console.log(err);
					return;
				}

				// Process user attributes
				const attr = Object.fromEntries(
					attributes.map((attribute) => {
						const name = attribute.getName();

						return [name, attribute.getValue()];
					}),
				);

				if (attr[selectedAttribute]) {
					callback(attr[selectedAttribute]);
				} else {
					callback(fallback);
				}
			});
		});
	}
};


export const getUserDetails = (): UserType => {

	const token = getCognitoUserToken()['accessToken'];
	if (token) {
		const parsed = parseJwt(token);
		return {
			email: parsed.email,
			name: parseNameFromEmail(parsed.email),
			admin: parsed['cognito:groups'].includes('Admin'),
			externalTradeboxOnly: parsed['cognito:groups'].includes(cognitoExternalGroupForTradebox),
			groups: parsed['cognito:groups'],
			loginTime: parsed.auth_time,
		};
	} else {
		return {
			email: '',
			name: '',
			admin: false,
			groups: [],
			externalTradeboxOnly: false,
			loginTime: 0,
		};
	}
};

export const getCurrentUserBroker = (callback: (value: any) => void) =>
	getCurrentUserAttributes('custom:broker', callback, 'iifl');

export const getCurrentUserBrokerAsync = () => {
	return new Promise<string>((resolve) => {
		getCurrentUserBroker(resolve);
	});
};

export const getUserEmail = () => {
	return parseJwt(getCognitoUserToken()['accessToken'])['email'];
};


export const getCognitoSessionCookies = (req = null) => {
	const cookies = parseCookies({ req });
	return Object.keys(cookies).filter((item) => item.includes('CognitoIdentityServiceProvider'));
};

export const isCognitoSessionCookieValid = (req = null) => {
	return getCognitoSessionCookies(req).length >= 5;
};
