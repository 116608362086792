import { add, format, parse } from 'date-fns';

export const formatDateString = (number, inputFormat = 'yyyyMMddHHmmss', outputFormat = 'dd MMM yy, HH:mm') =>
	format(parse(number.toString(), inputFormat, new Date()), outputFormat);

export const parseDateString = (date, inputFormat = 'yyyyMMddHHmmss') =>
	parse(date.toString(), inputFormat, new Date());

// export const parseDate = (number, inputFormat = 'yyyyMMdd', outputFormat = 'dd MMM yy') => format(parse(number.toString(), inputFormat, new Date()), outputFormat);

export const relativeDelta = (base, delta: Duration, formatString = 'yyyyMMdd') => {
	return format(add(parse(base.toString(), formatString, new Date()), delta), formatString);
};

export const dateRange = (startDate, endDate) => {
	const dates = [];
	let currentDate = startDate;
	const addDays = function (days) {
		const date = new Date(this.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	};
	while (currentDate <= endDate) {
		dates.push(currentDate);
		currentDate = addDays.call(currentDate, 1);
	}
	return dates;
};

export const getDateInIndianStandardTime = (): Date => {
	return new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
};
