export const titleCase = (str) => {
	str = str.toLowerCase().split(' ');
	for (var i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(' ');
};

export const nameInitials = (str) => {
	return str
		.match(/(^\S\S?|\b\S)?/g)
		.join('')
		.match(/(^\S|\S$)?/g)
		.join('')
		.toUpperCase();
};

export const stringtoAscii = (str) => {
	return str
		.toString()
		.toLowerCase()
		.split('')
		.map((char) => char.charCodeAt());
};

export const rgbToHex = (r, g, b) =>
	'#' +
	[r, g, b]
		.map((x) => {
			return x.toString(16).padStart(2, '0');
		})
		.join('');

export const isEmail = (str) => {
	const matcher =
		/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	return matcher.test(str);
};

export const parseJwt = (token) => {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(''),
	);

	return JSON.parse(jsonPayload);
};

export const parseNameFromEmail = (email: string) => {
	return titleCase(email.split('@')[0].split('.')[0]);
};

export function verifyJWT(token: any) {
	try {
		const parsedJWT = parseJwt(token);

		if (!parsedJWT['cognito:username']) {
			return false;
		}

		return true;
	} catch {
		return false;
	}
}

export function isString(value: any): value is string {
	return typeof value === 'string';
}
