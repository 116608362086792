import { DropDownSelectItem } from '@yobeehq/ui-components';
import { Dispatch, SetStateAction } from 'react';
import { KeyedMutator } from 'swr';

export enum FILTER_KEY {
	ID = 'id',
	STATUS = 'status',
	STRIKE = 'strike',
	MARKET = 'market',
	MARKET_CAP = 'market_cap',
	CLOSED_DATE = 'closed_date',
	INSTRUMENT_TYPE = 'ins_type',
	EMPLOYEE_EMAIL = 'employee_email',
	GROUP_NAME = 'group_name',
	SYMBOL = 'symbol',
	CLOSED_BY = 'closed_by',
	CREATION_DATE_TIME = 'creation_date',
	TRANSACTION = 'transaction',
	HORIZON = 'horizon',
	EXCHANGE = 'exchange',
	EXPIRY = 'expiry',
	VALIDITY = 'validity',
	MODIFICATION_DATE = 'modification_date',
}

export enum FILTER_NAMES {
	ID = 'ID',
	STATUS = 'Status',
	STRIKE = 'Strike',
	MARKET = 'Market',
	MARKET_CAP = 'Market Cap',
	CLOSED_DATE = 'Closed Date',
	INSTRUMENT_TYPE = 'Instrument Type',
	EMPLOYEE_EMAIL = 'Employee Email',
	GROUP_NAME = 'Group Name',
	SYMBOL = 'Symbol',
	CLOSED_BY = 'Closed by',
	CREATION_DATE_TIME = 'Created On',
	TRANSACTION = 'Transaction',
	HORIZON = 'Horizon',
	EXCHANGE = 'Exchange',
	EXPIRY = 'Expiry Date',
	VALIDITY = 'Validity',
	MODIFICATION_DATE = 'Modification Date',
}

export enum STATUS {
	OPEN = 'OPEN',
	CLOSE = 'CLOSE',
	PENDING = 'PENDING',
}

export enum MARKETS_VALUES {
	EQUITY = 'equity',
	COMMODITY = 'commodity',
	CURRENCY = 'currency',
}
export enum MARKETS_NAMES {
	EQUITY = 'Equity',
	COMMODITY = 'Commodity',
	CURRENCY = 'Currency',
}

export enum MARKET_CAP_VALUES {
	SMALL = 'small cap',
	LARGE = 'large cap',
	MID = 'mid cap',
}
export enum MARKET_CAP_NAMES {
	SMALL = 'Small',
	LARGE = 'Large',
	MID = 'Mid',
}

export enum INSTRUMENT_TYPE_VALUES {
	CASH = 'cash',
	FUT = 'fut',
	PUT = 'put',
	CALL = 'call',
}
export enum INSTRUMENT_TYPE_NAMES {
	CASH = 'Cash',
	FUT = 'Fut',
	PUT = 'Put',
	CALL = 'Call',
}

export enum CLOSED_BY_VALUES {
	TARGET = 'target',
	STOPLOSS = 'stoploss',
	RESEARCHER = 'researcher',
	EXPIRY = 'expiry',
	ADMIN = 'admin',
}
export enum CLOSED_BY_NAMES {
	TARGET = 'Target',
	STOPLOSS = 'Stoploss',
	RESEARCHER = 'Researcher',
	EXPIRY = 'Expiry',
	ADMIN = 'Admin',
}

export enum TRANSACTION_VALUES {
	BUY = 'BUY',
	SELL = 'SELL',
}
export enum TRANSACTION_NAMES {
	BUY = 'Buy',
	SELL = 'Sell',
}

export enum HORIZON_VALUES {
	INTRADAY = 'intraday',
	DELIVERY = 'delivery',
}

export enum HORIZON_NAMES {
	INTRADAY = 'Intraday',
	DELIVERY = 'Delivery',
}

export enum EXCHANGE_VALUES {
	NSE = 'nse',
	BSE = 'bse',
	MCX = 'mcx',
	NCDEX = 'ncdex',
}

export enum EXCHANGE_NAMES {
	NSE = 'NSE',
	BSE = 'BSE',
	MCX = 'MCX',
	NCDEX = 'NCDEX',
}

export type FilterQueryBase = {
	name: FILTER_NAMES;
	key: FILTER_KEY;
	show: boolean;
};

export type FilterQuerySelectList = DropDownSelectItem;

export type FilterQuerySelect = FilterQueryBase & { type: 'select'; verb: 'in'; list: FilterQuerySelectList[] };
export type FilterQueryDate = FilterQueryBase & { type: 'date'; verb: 'between' };
export type FilterQueryFloat = FilterQueryBase & { type: 'float'; verb: 'between' };
export type FilterQueryString = FilterQueryBase & { type: 'string'; verb: 'equal' };

export type FilterQuery = FilterQuerySelect | FilterQueryDate | FilterQueryFloat | FilterQueryString;

export type BuiltQuery = {
	by: FILTER_KEY;
	in?: string[];
	between?: [number, number] | [];
};

export type BuiltQueries = { [key: string]: BuiltQuery };

export type QueryOptionBaseProps = {
	code: FilterQueryBase['key'];
	defaultIsOpen?: boolean;
};

export type QueryOptionSelectProps = QueryOptionBaseProps & {
	type: FilterQuerySelect['type'];
	verb: FilterQuerySelect['verb'];
	list: FilterQuerySelect['list'];
	value?: FilterQuerySelectList['value'][];
	onApply: (payload: BuiltQuery) => void;
};

export type QueryOptionDateProps = QueryOptionBaseProps & {
	type: FilterQueryDate['type'];
	verb: FilterQueryDate['verb'];
	value?: [number, number] | [];
	dateType: 'range' | 'single';
	defaultClicked: boolean;
	onApply: (payload: BuiltQuery) => void;
};
export type QueryOptionFloatProps = QueryOptionBaseProps & {
	type: FilterQueryFloat['type'];
	verb: FilterQueryFloat['verb'];
	value?: [number, number] | [];
	onApply: (payload: BuiltQuery) => void;
};
export type QueryOptionStringProps = QueryOptionBaseProps & {
	type: FilterQueryString['type'];
	verb: FilterQueryString['verb'];
	value?: string;
	onApply: (payload: BuiltQuery) => void;
};

export type QuerySelectProps = {
	filterQueries: FilterQuery[];
	item: BuiltQuery;
	showWhere: boolean;
	selectedVerb: 'and' | 'or';
	dateType: 'range' | 'single';
	defaultClicked: boolean;
	updateSelectedVerb: (payload: string) => void;
	onApply: (payload: BuiltQuery) => void;
	onDelete: (key: string) => void;
	onUpdate: (payload: { from: FilterQuery; to: FilterQuery }) => void;
};

export type FiltersQueryBuilderProps = {
	queries: FilterQuery[];
};

export enum SELECTED_VERB {
	AND = 'and',
	OR = 'or',
}

export type FilterContexType = {
	filters: BuiltQuery[];
	selectedVerb: SELECTED_VERB;
	isSolosLoading: boolean;
	soloIDs: string[];
	setSelectedVerb: Dispatch<SetStateAction<SELECTED_VERB>>;
	mutate: (...args: any) => void;
	onReset: () => void;
	onApply: (filters: BuiltQuery[]) => void;
};
