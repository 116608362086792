import type { ComponentSingleStyleConfig } from '@chakra-ui/react';
import { colors } from '../colors';

const select: ComponentSingleStyleConfig = {
	baseStyle: {
		':focus': {
			borderWidth: '2px',
		},
	},
	defaultProps: {
		focusBorderColor: 'orange.500',
		boxShadow: `0 0 0 1px ${colors.orange['500']}`,
		// borderWidth: '2px'
	},
};

export default select;
