import { SWRConfig } from 'swr';
import useLogout from '../../hooks/logout';
import AppNotification from './notification';
import useWriteOHLCFeed from '../../apiHandlers/priceFeed/write';
import FilterState from '../soloPage/toolbar/FiltersQueryBuilder/FilterState';
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import TopBarDeployBanner from './notification/deployBanner';
import useGetUserDetails from '../../hooks/userDetails';
import { APIResponseError } from '../../apiHandlers/fetcher';
import { StructError } from 'superstruct';

const AppContainer = ({ children }) => {
	const logout = useLogout();
	const router = useRouter();
	useWriteOHLCFeed();
	const user = useGetUserDetails();

	useEffect(() => {
		if (user.email !== '') {
			Sentry.setUser({ email: user.email });
		}
	}, [router.pathname]);

	return (
		<SWRConfig
			value={{
				onError: (error, key) => {
					console.log(key);
					if (error instanceof APIResponseError) {
						if (error.status === 401) {
							logout();
							return;
						}
					}

					if (error instanceof StructError) {
						console.error(error.failures());
						return;
					}

					console.log('API_SWR_ERROR: UNKNOWN', error);
				},
			}}
		>
			<FilterState>
				<TopBarDeployBanner />
				{children}
				<AppNotification />
			</FilterState>
		</SWRConfig>
	);
};

export default AppContainer;
