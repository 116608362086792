const useGreeting = () => {

	const hour = new Date().getHours();

	if (hour >= 4 && hour < 12) {
		return 'morning';
	}
	if (hour >= 12 && hour < 17) {
		return 'afternoon';
	}
	return 'evening';

};

export default useGreeting;