import { soloFieldNames, soloStates } from '../../components/soloPage/soloVariables';
import { formatDateString } from '../../utils/dateTime';
import { titleCase } from '../../utils/text';
import { orderFieldNames } from '../../components/soloPage/orderVariables';

export const transformSoloAPIResponseForFrontend = (item) => {
	return {
		[soloFieldNames.SOLO_ID.FRONTEND]: item[soloFieldNames.SOLO_ID.BACKEND],
		creationDateTimeStamp: item[soloFieldNames.CREATION_DATE_TIME.BACKEND],
		[soloFieldNames.CREATION_DATE_TIME.FRONTEND]: formatDateString(item[soloFieldNames.CREATION_DATE_TIME.BACKEND]),
		[soloFieldNames.STATUS.FRONTEND]: item[soloFieldNames.STATUS.BACKEND],
		[soloFieldNames.CLOSED_BY.FRONTEND]: item[soloFieldNames.CLOSED_BY.BACKEND]
			? item[soloFieldNames.CLOSED_BY.BACKEND]
			: null,
		[soloFieldNames.OPEN_PRICE.FRONTEND]: item[soloFieldNames.OPEN_PRICE.BACKEND]
			? item[soloFieldNames.OPEN_PRICE.BACKEND]
			: null,
		[soloFieldNames.CLOSE_PRICE.FRONTEND]: item[soloFieldNames.CLOSE_PRICE.BACKEND]
			? item[soloFieldNames.CLOSE_PRICE.BACKEND]
			: null,
		[soloFieldNames.BROKER_CODE.FRONTEND]: item[soloFieldNames.BROKER_CODE.BACKEND],
		[soloFieldNames.BROKER_EXCHANGE.FRONTEND]: item[soloFieldNames.BROKER_EXCHANGE.BACKEND],
		[soloFieldNames.BROKER_EXCHANGE_TYPE.FRONTEND]: item[soloFieldNames.BROKER_EXCHANGE_TYPE.BACKEND],
		[soloFieldNames.EXCHANGE.FRONTEND]: item[soloFieldNames.EXCHANGE.BACKEND],
		[soloFieldNames.SYMBOL.FRONTEND]: item[soloFieldNames.SYMBOL.BACKEND],
		[soloFieldNames.MARKET.FRONTEND]: item[soloFieldNames.MARKET.BACKEND],
		[soloFieldNames.INS_TYPE.FRONTEND]: item[soloFieldNames.INS_TYPE.BACKEND],
		[soloFieldNames.EXPIRY.FRONTEND]: item[soloFieldNames.EXPIRY.BACKEND]
			? formatDateString(item[soloFieldNames.EXPIRY.BACKEND], 'yyyyMMdd', 'dd MMM yy')
			: null,
		expiryTimeStamp: item[soloFieldNames.EXPIRY.BACKEND] ? item[soloFieldNames.EXPIRY.BACKEND] : null,
		[soloFieldNames.STRIKE.FRONTEND]: item[soloFieldNames.STRIKE.BACKEND]
			? item[soloFieldNames.STRIKE.BACKEND]
			: null,
		[soloFieldNames.PRETTY_NAME.FRONTEND]: item[soloFieldNames.PRETTY_NAME.BACKEND],
		[soloFieldNames.TRANSACTION.FRONTEND]: item[soloFieldNames.TRANSACTION.BACKEND],
		[soloFieldNames.ENTRY_PRICE.FRONTEND]: {
			[soloFieldNames.ENTRY_PRICE.ENTRY_FROM.FRONTEND]:
				item[soloFieldNames.ENTRY_PRICE.BACKEND][soloFieldNames.ENTRY_PRICE.ENTRY_FROM.BACKEND],
			[soloFieldNames.ENTRY_PRICE.ENTRY_TO.FRONTEND]:
				item[soloFieldNames.ENTRY_PRICE.BACKEND][soloFieldNames.ENTRY_PRICE.ENTRY_TO.BACKEND],
		},
		[soloFieldNames.STOPLOSS.FRONTEND]:
			item[soloFieldNames.STOPLOSS.BACKEND] || item[soloFieldNames.STOPLOSS.BACKEND] === 0
				? item[soloFieldNames.STOPLOSS.BACKEND]
				: null,
		[soloFieldNames.TARGET.FRONTEND]:
			item[soloFieldNames.TARGET.BACKEND] || item[soloFieldNames.TARGET.BACKEND] === 0
				? item[soloFieldNames.TARGET.BACKEND]
				: null,
		[soloFieldNames.LOT_SIZE.FRONTEND]: item[soloFieldNames.LOT_SIZE.BACKEND]
			? item[soloFieldNames.LOT_SIZE.BACKEND]
			: null,
		[soloFieldNames.HORIZON.FRONTEND]: item[soloFieldNames.HORIZON.BACKEND],
		[soloFieldNames.VALIDITY.FRONTEND]: item[soloFieldNames.VALIDITY.BACKEND]
			? formatDateString(item[soloFieldNames.VALIDITY.BACKEND], 'yyyyMMdd', 'dd MMM yy')
			: null,
		[soloFieldNames.CLOSE_DATE_TIME.FRONTEND]: item[soloFieldNames.CLOSE_DATE_TIME.BACKEND]
			? formatDateString(item[soloFieldNames.CLOSE_DATE_TIME.BACKEND], 'yyyyMMddHHmmss', 'dd MMM yy HH:mm')
			: null,
		validityTimeStamp: item[soloFieldNames.VALIDITY.BACKEND] ? item[soloFieldNames.VALIDITY.BACKEND] : null,
		[soloFieldNames.SECTOR.FRONTEND]: item[soloFieldNames.SECTOR.BACKEND]
			? item[soloFieldNames.SECTOR.BACKEND]
			: null,
		[soloFieldNames.MARKET_CAP.FRONTEND]: item[soloFieldNames.MARKET_CAP.BACKEND]
			? item[soloFieldNames.MARKET_CAP.BACKEND]
			: null,
		[soloFieldNames.REMARKS.FRONTEND]: item[soloFieldNames.REMARKS.BACKEND]
			? item[soloFieldNames.REMARKS.BACKEND]
			: null,
		[soloFieldNames.REPORT_URL.FRONTEND]: item[soloFieldNames.REPORT_URL.BACKEND]
			? item[soloFieldNames.REPORT_URL.BACKEND]
			: null,
		[soloFieldNames.TAGS.FRONTEND]: item[soloFieldNames.TAGS.BACKEND] ? item[soloFieldNames.TAGS.BACKEND] : {},
		[soloFieldNames.GROUP.FRONTEND]: item[soloFieldNames.GROUP.BACKEND] ? item[soloFieldNames.GROUP.BACKEND] : null,
		[soloFieldNames.EMPLOYEE.FRONTEND]: item[soloFieldNames.EMPLOYEE.BACKEND]
			? item[soloFieldNames.EMPLOYEE.BACKEND]
			: null,
		[soloFieldNames.ORDERS_PLACED.FRONTEND]: item[soloFieldNames.ORDERS_PLACED.BACKEND]
			? item[soloFieldNames.ORDERS_PLACED.BACKEND].toFixed(0)
			: 0,
		[soloFieldNames.TRADING_VOLUME.FRONTEND]: item[soloFieldNames.TRADING_VOLUME.BACKEND]
			? item[soloFieldNames.TRADING_VOLUME.BACKEND].toFixed(0)
			: 0,
	};
};

export const transformSoloAPIResponseForReport = (item) => {
	const entry = +(
		(item[soloFieldNames.ENTRY_PRICE.BACKEND][soloFieldNames.ENTRY_PRICE.ENTRY_FROM.BACKEND] +
			item[soloFieldNames.ENTRY_PRICE.BACKEND][soloFieldNames.ENTRY_PRICE.ENTRY_TO.BACKEND]) /
		2
	).toFixed(2);
	const close = item[soloFieldNames.CLOSE_PRICE.BACKEND] ? +item[soloFieldNames.CLOSE_PRICE.BACKEND].toFixed(2) : 0;
	let plPct =
		item[soloFieldNames.TRANSACTION.BACKEND] === soloStates.TRANSACTION.BUY.code ? close - entry : entry - close;
	plPct = +(plPct / entry).toFixed(4);
	const acc = plPct > 0 ? 1 : 0;
	// console.log(entry, close, plPct);

	return {
		creationDateStamp: +formatDateString(
			item[soloFieldNames.CREATION_DATE_TIME.BACKEND],
			'yyyyMMddHHmmss',
			'yyyyMMdd',
		),
		[soloFieldNames.CREATION_DATE.REPORT]: formatDateString(
			item[soloFieldNames.CREATION_DATE_TIME.BACKEND],
			'yyyyMMddHHmmss',
			'dd MMM yyyy',
		),
		[soloFieldNames.CREATION_TIME.REPORT]: formatDateString(
			item[soloFieldNames.CREATION_DATE_TIME.BACKEND],
			'yyyyMMddHHmmss',
			'HH:mm',
		),
		[soloFieldNames.EMPLOYEE.REPORT]: item[soloFieldNames.EMPLOYEE.BACKEND],
		[soloFieldNames.PRETTY_NAME.REPORT]: item[soloFieldNames.PRETTY_NAME.BACKEND],
		[soloFieldNames.EXCHANGE.REPORT]: item[soloFieldNames.EXCHANGE.BACKEND].toUpperCase(),
		[soloFieldNames.MARKET.REPORT]: titleCase(item[soloFieldNames.MARKET.BACKEND].toUpperCase()),
		[soloFieldNames.INS_TYPE.REPORT]: titleCase(item[soloFieldNames.INS_TYPE.BACKEND]),
		[soloFieldNames.TRANSACTION.REPORT]: item[soloFieldNames.TRANSACTION.BACKEND],
		[soloFieldNames.ENTRY_PRICE.REPORT]: entry,
		[soloFieldNames.CLOSE_PRICE.REPORT]: close,
		[soloFieldNames.PROFIT_PCT.REPORT]: plPct,
		[soloFieldNames.CLOSED_BY.REPORT]: item[soloFieldNames.CLOSED_BY.BACKEND],
		[soloFieldNames.TARGET.REPORT]: +item[soloFieldNames.TARGET.BACKEND].toFixed(2),
		[soloFieldNames.STOPLOSS.REPORT]: +item[soloFieldNames.STOPLOSS.BACKEND].toFixed(2),
		[soloFieldNames.VALIDITY.REPORT]: formatDateString(
			item[soloFieldNames.VALIDITY.BACKEND],
			'yyyyMMdd',
			'dd MMM yyyy',
		),
		[soloFieldNames.HORIZON.REPORT]: item[soloFieldNames.HORIZON.BACKEND],
		[soloFieldNames.STATUS.REPORT]: item[soloFieldNames.STATUS.BACKEND],
		[soloFieldNames.CLOSE_DATE.REPORT]: item[soloFieldNames.CLOSE_DATE_TIME.BACKEND]
			? formatDateString(item[soloFieldNames.CLOSE_DATE_TIME.BACKEND], 'yyyyMMddHHmmss', 'dd MMM yyyy')
			: '',
		[soloFieldNames.CLOSE_TIME.REPORT]: item[soloFieldNames.CLOSE_DATE_TIME.BACKEND]
			? formatDateString(item[soloFieldNames.CLOSE_DATE_TIME.BACKEND], 'yyyyMMddHHmmss', 'HH:mm')
			: '',
		closeDateStamp: item[soloFieldNames.CLOSE_DATE_TIME.BACKEND]
			? +formatDateString(item[soloFieldNames.CLOSE_DATE_TIME.BACKEND], 'yyyyMMddHHmmss', 'yyyyMMdd')
			: 0,
		[soloFieldNames.ACCURACY_FLAG.REPORT]: acc,
		[soloFieldNames.ORDERS_PLACED.REPORT]: item[soloFieldNames.ORDERS_PLACED.BACKEND]
			? item[soloFieldNames.ORDERS_PLACED.BACKEND]
			: 0,
		[soloFieldNames.TRADING_VOLUME.REPORT]: item[soloFieldNames.TRADING_VOLUME.BACKEND]
			? item[soloFieldNames.TRADING_VOLUME.BACKEND]
			: 0,
	};
};

export const transformSoloForDispatch = (solo) => {
	return {
		solo: {
			[soloFieldNames.SYMBOL.BACKEND]: solo.symbol,
			[soloFieldNames.EXCHANGE.BACKEND]: solo.exchange,
			[soloFieldNames.MARKET.BACKEND]: solo.market,
			[soloFieldNames.INS_TYPE.BACKEND]: solo.insType,
			[soloFieldNames.BROKER_CODE.BACKEND]: solo.brokerCode,
			[soloFieldNames.BROKER_EXCHANGE.BACKEND]: solo.brokerExchange,
			[soloFieldNames.BROKER_EXCHANGE_TYPE.BACKEND]: solo.brokerExchangeType,
			[soloFieldNames.BROKER_ISIN.BACKEND]: solo.brokerISIN,
			...(solo.expiry && { [soloFieldNames.EXPIRY.BACKEND]: solo.expiry }),
			...(solo.strike && { [soloFieldNames.STRIKE.BACKEND]: solo.strike }),
			[soloFieldNames.ENTRY_PRICE.BACKEND]: {
				[soloFieldNames.ENTRY_PRICE.ENTRY_FROM.BACKEND]: solo.entryFrom,
				[soloFieldNames.ENTRY_PRICE.ENTRY_TO.BACKEND]: solo.entryTo,
			},
			[soloFieldNames.PRETTY_NAME.BACKEND]: solo.prettyName,
			[soloFieldNames.TRANSACTION.BACKEND]: solo.transaction,
			[soloFieldNames.STOPLOSS.BACKEND]: solo.stoploss,
			[soloFieldNames.TARGET.BACKEND]: solo.target,
			[soloFieldNames.LOT_SIZE.BACKEND]: solo.lotSize,
			[soloFieldNames.HORIZON.BACKEND]: solo.horizon,
			[soloFieldNames.VALIDITY.BACKEND]:
				typeof solo.validity === 'string' ? parseInt(solo.validity) : solo.validity,
			[soloFieldNames.SECTOR.BACKEND]: solo.sector === '' ? null : solo.sector,
			[soloFieldNames.MARKET_CAP.BACKEND]: solo.marketCap === '' ? null : solo.sector,
			[soloFieldNames.REMARKS.BACKEND]: solo.remarks,
			...(solo.reportData !== '' && {
				[soloFieldNames.REPORT_DATA.BACKEND]: {
					[soloFieldNames.REPORT_DATA.ENCODED_DATA.BACKEND]: solo.reportData,
				},
			}),
			[soloFieldNames.TAGS.BACKEND]: solo.tags,
			[soloFieldNames.GROUP.BACKEND]: solo.group,
			[soloFieldNames.EMPLOYEE.BACKEND]: solo.employee,
		},
	};
};

export const transformOrderAPIResponse = (item) => {
	let vol = +(item[orderFieldNames.QUANTITY.BACKEND] * item[orderFieldNames.PRICE.BACKEND]).toFixed(2);

	return {
		[orderFieldNames.ORDER_DATE_TIME.FRONTEND]: item[orderFieldNames.ORDER_DATE_TIME.BACKEND],
		[orderFieldNames.SOLO_ID.FRONTEND]: item[orderFieldNames.SOLO_ID.BACKEND],
		[orderFieldNames.ORDER_DATE.FRONTEND]: +formatDateString(
			item[orderFieldNames.ORDER_DATE_TIME.BACKEND],
			'yyyyMMddHHmmss',
			'yyyyMMdd',
		),
		[orderFieldNames.CLIENT_CODE.FRONTEND]: item[orderFieldNames.CLIENT_CODE.BACKEND],
		[orderFieldNames.TRANSACTION.FRONTEND]: item[orderFieldNames.TRANSACTION.BACKEND],
		[orderFieldNames.PRICE.FRONTEND]: +item[orderFieldNames.PRICE.BACKEND].toFixed(2),
		[orderFieldNames.QUANTITY.FRONTEND]: +item[orderFieldNames.QUANTITY.BACKEND].toFixed(0),
		[orderFieldNames.VOLUME.FRONTEND]: vol,
	};
};
