import { Link, chakra, keyframes } from '@chakra-ui/react';
import { useRouter } from 'next/dist/client/router';
import NextLink from 'next/link';
import { ReactElement } from 'react';

type Props = {
	href: string;
	title: string | ReactElement;
	isNew?: boolean;
};

const loading = keyframes`
	0% {
		background-position: 100% 0%;
	}
	100% {
		background-position: 0% 0%;
	}
`;

function ActiveLink({ href, title, isNew = false }: Props) {
	const { asPath } = useRouter();
	const comparePath = `/${asPath.split('/')[1]}`;

	const isActive = comparePath === href;
	return (
		<NextLink href={href} passHref>
			<Link
				py={'5'}
				color={isActive ? `purple.500` : isNew ? 'transparent' : `blackAlpha.600`}
				fontWeight={500}
				position={'relative'}
				backgroundClip={isNew && !isActive ? 'text' : undefined}
				bgImage={
					isNew && !isActive
						? `linear-gradient(
									75deg,
								  hsl(0deg 0% 56%) 0%,
								  hsl(0deg 0% 56%) 9%,
								  hsl(0deg 0% 56%) 18%,
								  hsl(263deg 7% 59%) 27%,
								  hsl(265deg 36% 67%) 36%,
								  hsl(266deg 73% 73%) 45%,
								  hsl(266deg 73% 73%) 55%,
								  hsl(265deg 36% 67%) 64%,
								  hsl(263deg 7% 59%) 73%,
								  hsl(0deg 0% 56%) 82%,
								  hsl(0deg 0% 56%) 91%,
								  hsl(0deg 0% 56%) 100%

				);`
						: undefined
				}
				backgroundSize={isNew && !isActive ? '400% 100%' : undefined}
				animation={isNew && !isActive ? `${loading} 5s ease infinite` : undefined}
			>
				<chakra.span>{title}</chakra.span>
			</Link>
		</NextLink>
	);
}

export default ActiveLink;
