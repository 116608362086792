export const soloFieldNames = {
	SOLO_ID: {
		BACKEND: 'id',
		FRONTEND: 'soloID',
	},
	CREATION_DATE_TIME: {
		BACKEND: 'creation_date',
		FRONTEND: 'creationDate',
	},
	CREATION_DATE: {
		REPORT: 'Date',
	},
	CREATION_TIME: {
		REPORT: 'Time',
	},
	CLOSE_DATE_TIME: {
		BACKEND: 'closed_date',
		FRONTEND: 'closedDate',
	},
	CLOSE_DATE: {
		REPORT: 'Close Date',
	},
	CLOSE_TIME: {
		REPORT: 'Close Time',
	},
	STATUS: {
		BACKEND: 'status',
		FRONTEND: 'status',
		REPORT: 'Status',
	},
	CLOSED_BY: {
		BACKEND: 'closed_by',
		FRONTEND: 'closedBy',
		REPORT: 'Closed By',
	},
	OPEN_PRICE: {
		BACKEND: 'open_price',
		FRONTEND: 'openPrice',
	},
	CLOSE_PRICE: {
		BACKEND: 'closed_price',
		FRONTEND: 'closePrice',
		REPORT: 'Close Price',
	},
	BROKER_CODE: {
		BACKEND: 'broker_code',
		FRONTEND: 'brokerCode',
	},
	BROKER_EXCHANGE: {
		BACKEND: 'broker_exchange',
		FRONTEND: 'brokerExchange',
	},
	BROKER_EXCHANGE_TYPE: {
		BACKEND: 'broker_exchange_type',
		FRONTEND: 'brokerExchangeType',
	},
	BROKER_ISIN: {
		BACKEND: 'broker_isin',
		FRONTEND: 'brokerISIN',
	},
	EXCHANGE: {
		BACKEND: 'exchange',
		FRONTEND: 'exchange',
		REPORT: 'Exchange',
	},
	SYMBOL: {
		BACKEND: 'symbol',
		FRONTEND: 'symbol',
		REPORT: 'Symbol',
	},
	MARKET: {
		BACKEND: 'market',
		FRONTEND: 'market',
		REPORT: 'Market',
	},
	INS_TYPE: {
		BACKEND: 'ins_type',
		FRONTEND: 'insType',
		REPORT: 'Instrument Type'
	},
	EXPIRY: {
		BACKEND: 'expiry',
		FRONTEND: 'expiry',
		REPORT: 'Expiry Date',
	},
	STRIKE: {
		BACKEND: 'strike',
		FRONTEND: 'strike',
		REPORT: 'Strike Price',
	},
	PRETTY_NAME: {
		BACKEND: 'full_name',
		FRONTEND: 'prettyName',
		REPORT: 'Symbol',
	},
	TRANSACTION: {
		BACKEND: 'transaction',
		FRONTEND: 'transaction',
		REPORT: 'Transaction',
	},
	ENTRY_PRICE: {
		BACKEND: 'entry_price',
		FRONTEND: 'entry',
		REPORT: 'Entry Price',
		ENTRY_FROM: {
			BACKEND: 'from',
			FRONTEND: 'from',
		},
		ENTRY_TO: {
			BACKEND: 'to',
			FRONTEND: 'to',
		},
	},
	STOPLOSS: {
		BACKEND: 'stoploss_price',
		FRONTEND: 'stoploss',
		REPORT: 'Stoploss',
	},
	TARGET: {
		BACKEND: 'target_price',
		FRONTEND: 'target',
		REPORT: 'Target',
	},
	LOT_SIZE: {
		BACKEND: 'lot_size',
		FRONTEND: 'lotSize',
	},
	HORIZON: {
		BACKEND: 'horizon',
		FRONTEND: 'horizon',
		REPORT: 'Horizon',
	},
	VALIDITY: {
		BACKEND: 'validity',
		FRONTEND: 'validity',
		REPORT: 'Validity',
	},
	SECTOR: {
		BACKEND: 'sector',
		FRONTEND: 'sector',
	},
	MARKET_CAP: {
		BACKEND: 'market_cap',
		FRONTEND: 'marketCap',
	},
	REMARKS: {
		BACKEND: 'remarks',
		FRONTEND: 'remarks',
	},
	REPORT_URL: {
		BACKEND: 'report_url',
		FRONTEND: 'reportURL',
	},
	REPORT_DATA: {
		BACKEND: 'report_data',
		FRONTEND: 'reportData',
		FILE_TYPE: {
			BACKEND: 'file_type',
			FRONTEND: 'fileType',
		},
		ENCODED_DATA: {
			BACKEND: 'encoded_data',
			FRONTEND: 'encodedData',
		},
	},
	TAGS: {
		BACKEND: 'tags',
		FRONTEND: 'tags',
		BRAND_CATEGORY: {
			BACKEND: 'brandCategory',
			FRONTEND: 'brandCategory',
		},

		BRAND_NAME: {
			BACKEND: 'brandName',
			FRONTEND: 'brandName',
		},
	},
	GROUP: {
		BACKEND: 'group_name',
		FRONTEND: 'group',
	},
	EMPLOYEE: {
		BACKEND: 'employee_email',
		FRONTEND: 'employee',
		REPORT: 'Analyst',
	},
	ORDERS_PLACED: {
		BACKEND: 'clients_placed_orders',
		FRONTEND: 'ordersPlaced',
		REPORT: 'Orders',
	},
	TRADING_VOLUME: {
		BACKEND: 'total_order_value',
		FRONTEND: 'tradingVolume',
		REPORT: 'Trading Volume',
	},
	PROFIT_PCT: {
		REPORT: 'Profit in %',
	},
	ACCURACY_FLAG: {
		REPORT: 'accuracyFlag',
	},
};

export const soloStates = {
	USER_GROUPS: {
		IIFL: {
			code: 'iifl',
			text: 'IIFL',
		},
		EXTERNAL: {
			code: 'external',
			text: 'External',
		},
	},
	STATUS: {
		OPEN: {
			code: 'OPEN',
			text: 'Open',
		},
		CLOSE: {
			code: 'CLOSED',
			text: 'Closed',
		},
		PENDING: {
			code: 'PENDING',
			text: 'Pending',
		},
	},
	CLOSED_BY: {
		TARGET: {
			code: 'target',
			text: 'Target Achieved',
		},
		STOPLOSS: {
			code: 'stoploss',
			text: 'Stoploss Hit',
		},
		USER: {
			code: 'researcher',
			text: 'Closed',
		},
		EXPIRY: {
			code: 'expire',
			text: 'Expired',
		},
	},
	TRANSACTION: {
		BUY: {
			code: 'BUY',
			text: 'BUY',
		},
		SELL: {
			code: 'SELL',
			text: 'SELL',
		},
	},
	HORIZON: {
		INTRADAY: {
			code: 'intraday',
			text: 'Intraday',
			days: 0,
		},
		SHORT: {
			code: 'short_term',
			text: 'Short Term',
			days: 30,
		},
		LONG: {
			code: 'long_term',
			text: 'Long Term',
			days: 365,
		},
	},
	MARKET_CAP: {
		LARGE: {
			code: 'large_cap',
			text: 'Large Cap',
		},
		MID: {
			code: 'mid_cap',
			text: 'Mid Cap',
		},
		SMALL: {
			code: 'small_cap',
			text: 'Small Cap',
		},
	},
	TAGS: {
		BRAND_CATEGORY: {
			code: 'brand_category',
			text: 'Brand Category',
			values: ['Option Multiplier', 'Buzzing Stocks', 'Event Specific', 'High Moment', 'BNPL', 'Other'],
		},
		BRAND_NAME: {
			code: 'brand_name',
			text: 'Brand Name',
		},
	},
	API_FETCH_MODES: {
		ALL: 'all',
		IDS: 'ids',
		COUNT: 'count',
	},
	ADD_SOLO_ERRORS: {
		ALREADY_EXISTS: {
			code: 'already_exist',
			message: 'There is an active solo for this instrument',
		},
	},
	WEBSOCKET_EVENTS: {
		NEW: {
			code: 'new',
			message: ' has been added',
		},
		OPEN: {
			code: 'open',
			message: 'is now open',
		},
		EXPIRE: {
			code: 'expired',
			message: 'has expired',
		},
		STOPLOSS_HIT: {
			code: 'closed_by_stoploss',
			message: 'is closed by stoploss',
		},
		TARGET_HIT: {
			code: 'closed_by_target',
			message: 'has achieved target ',
		},
		EMPLOYEE_CLOSE: {
			code: 'closed_by_researcher',
			message: 'is closed by analyst',
		},
		ADMIN_CLOSE: {
			code: 'closed_by_admin',
			message: 'is closed by analyst',
		},
	},
} as const;
