export type BrokerConfigItemType = {
	priceFeedBadge: string;
	tradeboxNamePrefix: string;
	tag: string;
	owner: string;
};

export type UserType = {
	email: string,
	name: string,
	admin: boolean,
	groups: string[],
	externalTradeboxOnly: boolean,
	loginTime: number,
}

export const brokerMap: Record<'iifl' | 'yobee' | 'axis' | 'sbi', BrokerConfigItemType> = {
	iifl: {
		priceFeedBadge: 'IIFL',
		tradeboxNamePrefix: "IIFL's",
		tag: 'iifl_strategy',
		owner: 'iifl',
	},
	axis: {
		priceFeedBadge: 'Axis',
		tradeboxNamePrefix: "Axis'",
		tag: 'yobee_strategy',
		owner: 'axis',
	},
	sbi: {
		priceFeedBadge: 'SBI',
		tradeboxNamePrefix: "SBI's",
		tag: 'yobee_strategy',
		owner: 'sbi',
	},
	yobee: {
		priceFeedBadge: '',
		tradeboxNamePrefix: '',
		tag: 'yobee_strategy',
		owner: 'yobee',
	},
};

export const cognitoExternalGroupForTradebox = 'External'
