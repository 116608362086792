const globalStyles = {
  global: () => ({
    html: {
      scrollBehavior: 'smooth',
    },
    body: {
      bg: '#F8FBFF',
      color: 'black',
      lineHeight: 'normal',
      scrollBehavior: 'smooth',
      fontFamily: `'Archivo', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    },
  }),
};

export default globalStyles;
