import useSWR from 'swr';
import { fetcherWithFiltersWithAndOR } from '../fetcher';
import { soloFieldNames, soloStates } from '../../components/soloPage/soloVariables';
import { transformSoloAPIResponseForFrontend } from './helper';
import { apiEndPoints } from '../endpoints';

const useGetSoloWithFilters = (mode: string, filters: string, filterMode = 'and', fetch = true) => {
	const { data, error, mutate } = useSWR(
		fetch ? [apiEndPoints.SOLO.FILTER_SOLOS, mode, filters, filterMode] : null,
		fetcherWithFiltersWithAndOR,
	);
	// console.log('FILTER_SOLO_DATA', data)

	if (mode === soloStates.API_FETCH_MODES.ALL) {
		return {
			data: data
				? data['data']
					.map((item) => transformSoloAPIResponseForFrontend(item))
					.sort((a, b) => b.creationDateTimeStamp - a.creationDateTimeStamp)
				: data,
			isLoading: !error && !data,
			isError: error,
			mutate,
		};
	}

	if (mode === soloStates.API_FETCH_MODES.IDS) {
		return {
			data: data
				? data['data']
					.sort(
						(a, b) =>
							b[soloFieldNames.CREATION_DATE_TIME.BACKEND] -
							a[soloFieldNames.CREATION_DATE_TIME.BACKEND],
					)
					.map((item) => item[soloFieldNames.SOLO_ID.BACKEND])
				: data,
			isLoading: !error && !data,
			isError: error,
			mutate,
		};
	}

	return {
		data: data ? data['data'] : data,
		isLoading: !error && !data,
		isError: error,
		mutate,
	};
};

export default useGetSoloWithFilters;
