import SoloNotification from './solo';
import { useRouter } from 'next/router';
import PriceFeedStatusNotification from './pricefeedStatus';

const AppNotification = () => {

	const router = useRouter();

	if (router.route === '/') {
		return null;
	}

	return (
		<>
			<SoloNotification />
			{/*<PriceFeedStatusNotification/>*/}
		</>
	);
};

export default AppNotification;