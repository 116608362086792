export const colors = {
	black: '#1F1F1F',
	white: '#FFFFFF',
	blackAlpha: {
		50: 'rgba(0, 0, 0, 0.04)',
		100: 'rgba(0, 0, 0, 0.06)',
		200: 'rgba(0, 0, 0, 0.08)',
		300: 'rgba(0, 0, 0, 0.16)',
		400: 'rgba(0, 0, 0, 0.24)',
		500: 'rgba(0, 0, 0, 0.36)',
		600: 'rgba(0, 0, 0, 0.48)',
		700: 'rgba(0, 0, 0, 0.64)',
		800: 'rgba(0, 0, 0, 0.8)',
		900: 'rgba(0, 0, 0, 0.92)',
	},
	blue: {
		50: '#F2F3FB',
		100: '#C2C8EB',
		200: '#A7BAE8',
		300: '#7492D2',
		400: '#4D66C7',
		500: '#1247BA',
		600: '#1F43B3',
		700: '#1139A7',
		800: '#002F9B',
		900: '#001C88',
	},
	red: {
		50: '#FDF6F8',
		100: '#F9BBC9',
		200: '#F490A5',
		300: '#EE6583',
		400: '#E84769',
		500: '#E23051',
		600: '#D22B4F',
		700: '#BD264C',
		800: '#A92049',
		900: '#851843',
	},
	purple: {
		50: '#FAF9FF',
		100: '#E4CFFF',
		200: '#D1ABFF',
		300: '#BB86FC',
		400: '#A271DF',
		500: '#8742DB',
		600: '#7013E1',
		700: '#50119E',
		800: '#480F8E',
		900: '#3B1E5F',
	},
	green: {
		50: '#F3FFF3',
		100: '#C4E9C1',
		200: '#9DDA9A',
		300: '#72CD6F',
		400: '#57B847',
		500: '#2CB932',
		600: '#11A71E',
		700: '#00950E',
		800: '#008400',
		900: '#006500',
	},
	orange: {
		50: '#FFF8EE',
		100: '#FCDAAF',
		200: '#FDC882',
		300: '#FCB151',
		400: '#FBA02F',
		500: '#FA9118',
		600: '#F58517',
		700: '#EF7516',
		800: '#E86615',
		900: '#A9370B',
	},
	teal: {
		50: '#ECFFFB',
		100: '#B2F5EA',
		200: '#81E6D9',
		300: '#55D5C9',
		400: '#37B7B1',
		500: '#32A19F',
		600: '#2C7A7B',
		700: '#285E61',
		800: '#234E52',
		900: '#1D4044',
	},
	yellow: {
		50: '#FFFAE6',
		100: '#FFF1C1',
		200: '#FFEA99',
		300: '#FDE172',
		400: '#FADA53',
		500: '#F7D434',
		600: '#EDCA3C',
		700: '#E3B618',
		800: '#BD9726',
		900: '#946C18',
	},
	cyan: {
		50: '#EDFDFD',
		100: '#C4F1F9',
		200: '#9DECF9',
		300: '#69E1F6',
		400: '#0BC5EA',
		500: '#00B5D8',
		600: '#00A3C4',
		700: '#0987A0',
		800: '#086F83',
		900: '#065666',
	},
	pink: {
		50: '#FFF5F7',
		100: '#FED7E2',
		200: '#FBB6CE',
		300: '#F687B3',
		400: '#ED64A6',
		500: '#D53F8C',
		600: '#B83280',
		700: '#97266D',
		800: '#702459',
		900: '#521B41',
	},
	gray: {
		50: '#F5F5F5',
		100: '#EEEAF2',
		200: '#DDD8E2',
		300: '#CAC7D1',
		400: '#AAA9AE',
		500: '#818085',
		600: '#555062',
		700: '#35323D',
		800: '#23202A',
		900: '#18161D',
	},
	whiteAlpha: {
		50: 'rgba(255, 255, 255, 0.04)',
		100: 'rgba(255, 255, 255, 0.06)',
		200: 'rgba(255, 255, 255, 0.08)',
		300: 'rgba(255, 255, 255, 0.16)',
		400: 'rgba(255, 255, 255, 0.24)',
		500: 'rgba(255, 255, 255, 0.36)',
		600: 'rgba(255, 255, 255, 0.48)',
		700: 'rgba(255, 255, 255, 0.64)',
		800: 'rgba(255, 255, 255, 0.8)',
		900: 'rgba(255, 255, 255, 0.92)',
	},
};
