import { HStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Stack } from '@chakra-ui/react';
import Image from 'next/image';
import Container from './Container';
import { Box, Text } from '@chakra-ui/layout';
import { useRouter } from 'next/router';
import useLogout from '../../hooks/logout';
import ActiveLink from './ActiveLink';
import useGreeting from '../../hooks/greeting';
import { IoCaretDownOutline } from 'react-icons/io5';
import { HiLogout } from 'react-icons/hi';
import useGetUserDetails from '../../hooks/userDetails';
import { useState } from 'react';
import { useIsServer } from '../../hooks/isServer';

function SiteHeader() {
	const isServer = useIsServer();
	const user = useGetUserDetails();

	const router = useRouter();

	const logout = useLogout();
	const handleLogout = () => {
		logout();
	};

	const greeting = useGreeting();

	if (router.route === '/') {
		return null;
	}

	return (
		<Box as={'header'} bg={'white'} boxShadow={`0px 1px 3px #E8EDF3`}>
			<Container>
				<HStack w={'full'} py={'6'} justifyContent={'space-between'} alignItems={`center`}>
					<HStack direction={`row`} spacing={24}>
						<Image src={'/logo/one-word-gradient.svg'} alt={`Yobee`} width={`100px`} height={`24px`} />
						{!user.externalTradeboxOnly && <ActiveLink href={`/dashboard`} title={`Dashboard`} />}
						{!user.externalTradeboxOnly && <ActiveLink href={`/solo`} title={`Solo`} />}
						<ActiveLink href={`/tradebox`} title={`Tradebox`} isNew={true} />
					</HStack>
					{/*<Text fontSize={'lg'} fontWeight={'bold'} color={'gray.800'} w={'100%'} textAlign={'center'}>*/}
					{/*	Solo Publisher*/}
					{/*</Text>*/}

					{!isServer && (
						<Menu matchWidth={true}>
							<MenuButton>
								<HStack>
									<Text>{`Good ${greeting}, ${user.name}`}</Text>
									<IoCaretDownOutline />
								</HStack>
							</MenuButton>
							<MenuList pt={4} pb={0}>
								<Stack fontSize={'sm'} px={3}>
									<Text fontSize={'xs'} color={'gray.500'}>
										Logged in:
									</Text>
									<Text color={'black'}>{user.email}</Text>
								</Stack>
								<MenuDivider mb={0} mt={4} />
								<MenuItem px={3} py={4} onClick={handleLogout}>
									<HStack fontSize={'sm'} color={'red.500'}>
										<HiLogout />
										<Text>Logout</Text>
									</HStack>
								</MenuItem>
							</MenuList>
						</Menu>
					)}
				</HStack>
			</Container>
		</Box>
	);
}

export default SiteHeader;
