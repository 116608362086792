import fetch from 'unfetch';
import { getCognitoUserToken } from '../utils/cognito';
import { Struct, create, is, assert } from 'superstruct';

export class APIResponseError extends Error {
	public status: number | null;
	public info: any;
	constructor(message: string) {
		super(message);
		this.status = null;
	}
}

export const adjustFeatcher = <Response = unknown>(responseSchema: Struct<Response>, method: string = 'GET') => {
	return async (endpoint: string, bodyString?: string) => {
		const response = await fetch(`${process.env.NEXT_PUBLIC_ADJUST_API}${endpoint}`, {
			method,
			headers: {
				'Content-Type': 'application/json',
				Authorization: getCognitoUserToken()['accessToken'],
			},
			body: bodyString,
		});

		if (!response.ok) {
			const error = new APIResponseError('An error occurred while fetching the data');
			error.info = await response.json();
			error.status = response.status;
			throw error;
		}

		const json = await response.json();
		assert(json, responseSchema);
		return json;
	};
};

export const featcherProxied = <Response = unknown>(responseSchema: Struct<Response>, method: string = 'GET') => {
	return async (url: string, bodyString?: string) => {
		const response = await fetch(url, {
			method,
			headers: {
				'Content-Type': 'application/json',
				Authorization: getCognitoUserToken()['accessToken'],
			},
			body: bodyString,
		});

		if (!response.ok) {
			const error = new APIResponseError('An error occurred while fetching the data');
			error.info = await response.json();
			error.status = response.status;
			throw error;
		}

		const json = await response.json();
		assert(json, responseSchema);
		return json;
	};
};

export const dispatcherProxied = <Response = unknown>(responseSchema: Struct<Response>, method: string = 'GET') => {
	return async (url: string, bodyString?: string) => {
		const response = await fetch(url, {
			method,
			headers: {
				'Content-Type': 'application/json',
				Authorization: getCognitoUserToken()['accessToken'],
			},
			body: bodyString,
		});

		const json = await response.json();
		assert(json, responseSchema);

		if (!response.ok) {
			const error = new APIResponseError('An error occurred while fetching the data');
			error.info = json;
			error.status = response.status;

			// TODO: ask backend that the response status to be consistence in case of error
			if (response.status !== 405) {
				throw error;
			}
		}

		return json;
	};
};

export const fetcher = async (url: string, key: string, value: any) => {
	const r = await fetch(`${process.env.NEXT_PUBLIC_BASE_API}${url}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: getCognitoUserToken()['accessToken'],
		},
		...(key && value && { body: JSON.stringify({ [key]: value }) }),
	});

	if (!r.ok) {
		const error = new APIResponseError('An error occurred while fetching the data');
		error.info = await r.json();
		error.status = r.status;
		throw error;
	}
	return r.json();
};

export const fetcherWithFilters = async (url: any, mode: any, filters: any) => {
	const r = await fetch(`${process.env.NEXT_PUBLIC_BASE_API}${url}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: getCognitoUserToken()['accessToken'],
		},
		body: JSON.stringify({
			mode: mode,
			filters,
		}),
	});

	if (!r.ok) {
		const error = new APIResponseError('An error occurred while fetching the data');
		error.info = await r.json();
		error.status = r.status;
		throw error;
	}
	return r.json();
};
export const fetcherWithFiltersWithAndOR = async (url: any, mode: any, filters: any, filterMode = 'or') => {
	if (filterMode === 'or') {
		const requests = JSON.parse(filters).map((filter) => fetcherWithFilters(url, mode, [filter]));
		const results: any[] = await Promise.all(requests);
		return {
			data: results
				.map((result) => result.data)
				.flat()
				.reduce((acc, item) => {
					// removing duplicate entries
					if (acc.find((i) => i.id === item.id)) {
						return [...acc];
					}
					return [...acc, item];
				}, []),
		};
	}

	return fetcherWithFilters(url, mode, JSON.parse(filters));
};

const LAST_EVALUATED_KEY = 'last_evaluated_key';

const fetcherFilterOrders = async (url, mode, filters, lastEvaluatedValue) => {
	const r = await fetch(`${process.env.NEXT_PUBLIC_BASE_API}${url}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: getCognitoUserToken()['accessToken'],
		},
		body: JSON.stringify({
			mode: mode,
			filters,
			[LAST_EVALUATED_KEY]: lastEvaluatedValue ?? null,
		}),
	});

	if (!r.ok) {
		const error = new APIResponseError('An error occurred while fetching the data');
		error.info = await r.json();
		error.status = r.status;
		throw error;
	}

	const parsedJson = await r.json();

	if (!parsedJson.isSuccess) {
		const error = new APIResponseError('An error occurred in fetched data');
		error.info = parsedJson;
		error.status = r.status;
		console.error({ parsedJson });
		throw error;
	}

	return parsedJson;
};

const fetcherFilterOrdersAll = async (url, mode, filters) => {
	const responseData = await fetcherFilterOrders(url, mode, filters, null);
	const data = responseData.data;
	let next = responseData[LAST_EVALUATED_KEY];

	while (next !== null) {
		const nextResponseData = await fetcherFilterOrders(url, mode, filters, next);
		next = nextResponseData[LAST_EVALUATED_KEY];
		data.push(...nextResponseData.data);
	}

	return { data };
};

export const fetcherAllFilterOrdersWithAndOr = async (url, mode, filters, filterMode) => {
	if (filterMode === 'or') {
		const requests = JSON.parse(filters).map((filter) => fetcherFilterOrdersAll(url, mode, [filter]));
		const results: any[] = await Promise.all(requests);
		return {
			data: results
				.map((result) => result.data)
				.flat()
				.reduce((acc, item) => {
					// removing duplicate entries
					if (acc.find((i) => i.id === item.id)) {
						return [...acc];
					}
					return [...acc, item];
				}, []),
		};
	}

	return fetcherFilterOrdersAll(url, mode, JSON.parse(filters));
};

export const fetcherOHLCFeed = async (url, exchange, exchangeType, code) => {
	const r = await fetch(`${process.env.NEXT_PUBLIC_BASE_API}${url}`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			Authorization: getCognitoUserToken()['accessToken'],
		},
		body: JSON.stringify([
			{
				exchange: exchange,
				exchange_type: exchangeType,
				broker_code: code,
			},
		]),
	});

	if (!r.ok) {
		const error = new APIResponseError('An error occurred while fetching the data');
		error.info = await r.json();
		error.status = r.status;
		throw error;
	}
	return r.json();
};
