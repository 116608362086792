import { ChakraProvider } from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible';
import { AppProps } from 'next/app';
import SiteHeader from '../components/atoms/Header';
import theme from '../theme';
import AppContainer from '../components/atoms/AppContainer';
import React from 'react';

function YRApp({ Component, pageProps }: AppProps) {
	return (
		<ChakraProvider theme={theme}>
			<AppContainer>
				<SiteHeader />
				<Component {...pageProps} />
			</AppContainer>
		</ChakraProvider>
	);
}

export default YRApp;
