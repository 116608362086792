import { extendTheme } from '@chakra-ui/react';
import breakpoints from './breakpoints';
import { colors } from './colors';
import Modal from './componentOverrides/modal';
import fonts from './fonts';
import styles from './styles';
import input from './componentOverrides/input';
import select from './componentOverrides/select';

const overrides = {
    config: {
        cssVarPrefix: 'yr',
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
    colors: colors,
    breakpoints: breakpoints,
    styles: styles,
    fonts: fonts,
    components: {
        Modal: Modal,
        Drawer: Modal,
        Input: input,
        Select: select,
        Textarea: input,
    },
};

const theme = extendTheme(overrides);

export default theme;
