import { useHasNewDeploy } from 'next-deploy-notifications';
import { Center, HStack } from '@chakra-ui/react';
import { Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';

const TopBarDeployBanner = () => {

	const { hasNewDeploy } = useHasNewDeploy({ debug: false });

	if (!hasNewDeploy) {
		return null;
	}

	return (
		<Center w={'100vw'} h={'36px'} background={'blue.400'}>
			<HStack color={'gray.50'}>
				<Text fontSize={'sm'}>
					Publisher App update available
				</Text>
				<Button variant={'link'} size={'sm'} color={'gray.100'} onClick={() => window.location.reload()}>
					Refresh
				</Button>
			</HStack>

		</Center>
	);
};

export default TopBarDeployBanner;